<template>
  <div class="investment-list-page">
    <InvestmentList />
  </div>
</template>

<script>
import InvestmentList from "../components/InvestmentList.vue";

export default {
  name: "Investments",
  components: { InvestmentList }
};
</script>
